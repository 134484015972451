import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const RathHeumar: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.RathHeumar,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Rath/Heumar"
          d="M804.1,712.2L806,682l11-6v-16l20-8l24,28l18-16l16-4l4-6l10-2l10-16h14l11.8-18.5c0.3-0.3,3.4-2,3.8-2.3 c1.3,3.2,3.1,8.1,4.3,11.2l6.1-3.6c-0.2-1.1-1.3-5.5-1.4-6.5c1.2-0.8,5-4.8,6.5-4.8c2.7-1.3,6.5-0.5,9.4-1.3c1.7,2.2,4.6,3,7.2,3.4 c1.6-0.6,3.9-0.3,5.6,0c0.2,0.2,0.7,0.6,0.9,0.8l0.8-0.1l0.3-0.5l0.5,0.1l0.2,0.4c0.3-0.1,0.9-0.1,1.2,0.1v0.4l1.5,0.5 c1-0.3,2.4-0.5,3.2-1.2l0.6,0.1l0.6,1.2l0.7,0.3l1-0.2c0.9-1.2,3.4-2.3,4.7-2.9c1.4,0.4,2.1-1.5,3.2-2c0.8,0,1.8-0.2,2.1-1 c0.5,0,1.7-0.1,2.2,0l0.5-0.6l0.1-0.6c0.5-0.5,1.5-0.4,2.1-0.3c0.3-0.4,1.2-1.5,1.3-1.9c0.3-0.2,0.9-0.2,1.3-0.1 c0.7,1.3,2.2,0.7,3-0.2l-0.1-1.4c0.5-0.5,1.9-0.9,2.6-1l0.4-0.9c0.5-0.2,1.6-0.6,2.1-0.7v0.8c0.7,0.1,3.3,0.6,3.8,1l0.1,0.5h0.4 l0.4-0.5l1.2,0.4c0.3-0.2,1-0.4,1.4-0.4l0.1-0.7l0.6-0.1l0.5,0.2c-0.1,1.7-0.7,3.6,0.9,4.9c0.8,2,0.9,3.4,0,5.3 c-0.7,2.6-1.8,9.1-2.5,11.9c-1.3,3.2-5.1,10.5-6.2,13.6c0.3,0.5-1.7,5.7-1.8,6.3c0.1,1.1,0.1,3.6-0.1,4.6c-0.9,1.8-3.5,6.7-3.5,8.7 c0.1,1,0.5,3.6-0.1,4.5c-2.6,5-4.8,12.4-7.6,17.3c-0.8,1.7,1.4,5.3,2,6.8c1.5,4,4.6,12,6.4,15.7c0.5,3.5-3,8.4-0.6,11.5 c0.1,1.5-2,3.3-2.8,4.5l-0.9-0.7h-24l-2-12h-52l-14-14l-28,8v14l12,14l-26,2l-20,14v12l-29-4l-28-38L804.1,712.2z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 916.177 672.5404)"
        >
          Rath/Heumar
        </text>
      </g>
    </>
  );
});
